import React from "react";

const About = () => {
  return (
    <>
      <div className="row align-items-center justify-content-center">
        <div
          className="col-lg-3 my-2"
          data-aos="fade-up"
          data-aos-duration="1200"
        >
          <div className="img-box">
            <img src="img/heimages/portfolio/author_photo_440_600.jpg" alt="smiling a girl" />
          </div>
        </div>

        <div
          className="col-lg-6 my-3"
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay="100"
        >
          <div className="typo-box about-me">
            <h3>H.E. Gober</h3>
            <h5>
              <span className="color-theme">High Fantasy</span> author and enthusiast.
            </h5>
            <p>
              Just another human who is spinning around in space on a planet called Earth. To escape the stress of everyday life you can find her reading in a cocoon of blankets. Once out of the reading nest she is chasing after her four children or talking nonsense with her husband (usually something about DnD and Video Games).
            </p>
            <p>
              That love for reading has recently mutated into a love for writing. Something completely new and scary. She has started, stopped, and scrapped so many different stories it&apos;s not even funny. But with the encouragement from two of her best friends she finally sat down and wrote a story from beginning to end.
            </p>
            <p>
              Does she know where this new passion will take her? Absolutely not. Will it be any good? Ummmm…maybe. Will she enjoy the ride? 99% yes with 1% grumbling.
            </p>
            <div className="row about-list">
              <div className="col-md-6">
                <div className="media">
                  <label>Birthday</label>
                  <p>October 28th</p>
                </div>
                <div className="media">
                  <label>Favorite Dessert</label>
                  <p>Cheesecake</p>
                </div>
                <div className="media">
                  <label>Hobbies</label>
                  <p>Reading, Crochet, DnD, Video games, Coming up with new WIP, TikTok</p>
                </div>
                <div className="media">
                  <label>Love</label>
                  <p>Plants, Plants, Plants! </p>
                </div>
              </div>
              {/* End .col */}

              <div className="col-md-6">
              <div className="media">
                  <label>Residence</label>
                  <p>USA</p>
                </div>
                <div className="media">
                  <label>Favorite Color</label>
                  <p>Teal</p>
                </div>
                <div className="media">
                  <label>Favorite Quote</label>
                  <p>&quot;My philosophy is that worrying means you suffer twice.&quot; <br></br>- Newt Scamander</p>
                </div>
              </div>
              {/* End .col */}
            </div>
            {/* End .row */}
          </div>
          {/* End .about me */}
        </div>
        {/* End .col */}
      </div>
    </>
  );
};

export default About;
