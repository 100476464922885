import React, { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import SimpleReactLightbox from "simple-react-lightbox";
import Modal from "react-modal";

const tabList = ["All", "Trials Of The Heart", /*"Galactic University",*/ "Stand Alone Titles"];

const AllContent = [
  {
    img: "toh_cover",
    title: "Trials Of The Heart",
    subTitle: "The Heart Series",
    alterText: "Trials Of The Heart",
    delayAnimation: "",
    portfolioLink:
      "https://play.google.com/store/apps/details?id=com.alightcreative.motion&hl=en&gl=US",
  },
  {
    img: "soh_cover",
    title: "To Be Announced",
    subTitle: "The Heart Series",
    alterText: "TBA",
    delayAnimation: "100",
    portfolioLink:
      "https://dribbble.com/shots/16529350-Nairo-Personal-Portfolio-React-Template",
  },
  {
    img: "owned_cover",
    title: "Owned",
    subTitle: "Stand Alone",
    alterText: "Owned",
    delayAnimation: "100",
    portfolioLink:
      "https://dribbble.com/shots/16529350-Nairo-Personal-Portfolio-React-Template",
  }
  /*{
    img: "novella_cover",
    title: "To Be Announced",
    subTitle: "The Heart Series - Novella",
    alterText: "TBA",
    delayAnimation: "200",
    portfolioLink:
      "https://dribbble.com/shots/16529350-Nairo-Personal-Portfolio-React-Template",
  },
  {
    img: "sat_cover",
    title: "To Be Announced",
    subTitle: "Stand Alone Title",
    alterText: "TBA",
    delayAnimation: "300",
    portfolioLink:
      "https://dribbble.com/shots/16529350-Nairo-Personal-Portfolio-React-Template",
  },*/
];
const StandAloneContent = [
  {
    img: "owned_cover",
    title: "Owned",
    subTitle: "Stand Alone",
    alterText: "Owned",
    delayAnimation: "300",
    portfolioLink:
      "https://dribbble.com/shots/16529350-Nairo-Personal-Portfolio-React-Template",
  },
];

const TrialsOfTheHeartContent = [
  {
    img: "toh_cover",
    title: "Trials Of The Heart",
    subTitle: "The Heart Series - Book 1",
    alterText: "Trials of The Heart",
    delayAnimation: "",
    portfolioLink:
      "https://play.google.com/store/apps/details?id=com.alightcreative.motion&hl=en&gl=US",
  },
  {
    img: "soh_cover",
    title: "To Be Announced",
    subTitle: "The Heart Series - Book 2",
    alterText: "TBA",
    delayAnimation: "100",
    portfolioLink:
      "https://dribbble.com/shots/16529350-Nairo-Personal-Portfolio-React-Template",
  }
  /*{
    img: "novella_cover",
    title: "To Be Announced",
    subTitle: "The Heart Series - Novella",
    alterText: "TBA",
    delayAnimation: "200",
    portfolioLink:
      "https://dribbble.com/shots/16529350-Nairo-Personal-Portfolio-React-Template",
  },*/
];
/*
const GalacticUniveristyContent = [
  {
    img: "rdmbbf_cover",
    title: "Revenge Dating My Brother's Best Friend",
    subTitle: "Galactic University",
    alterText: "Revenge Dating My Brother's Best Friend",
    delayAnimation: "100",
    portfolioLink:
      "https://dribbble.com/shots/16529350-Nairo-Personal-Portfolio-React-Template",
  },
];
*/

const Books = () => {
  const [tohIsOpen, trialsOfTheHeartIsOpen] = useState(false);
  const [sohIsOpen, sacrificeOfTheHeartIsOpen] = useState(false);
  /*const [novIsOpen, novellaIsOpen] = useState(false);*/
  const [satIsOpen, standAloneIsOpen] = useState(false);
  //const [rdmbbfIsOpen, revengeDatingMyBrothersBestFriendIsOpen] = useState(false);

   const toggleModal = (param) =>{

    switch(param){
      case 'toh_cover':
        trialsOfTheHeartIsOpen(!tohIsOpen);
        break;
      case 'soh_cover':
        sacrificeOfTheHeartIsOpen(!sohIsOpen);
        break;
     /* case 'novella_cover':
        novellaIsOpen(!novIsOpen);
        break;*/
      case 'owned_cover':
        standAloneIsOpen(!satIsOpen);
        break;
    /*  case 'rdmbbf_cover':
        revengeDatingMyBrothersBestFriendIsOpen(!rdmbbfIsOpen);
        break;*/
      default:
        break;
    }
  }
  return (
    <SimpleReactLightbox>
      <div className="positon-relative">
        <div className="portfolio-filter-01">
          <Tabs>
            <TabList className="filter d-flex justify-content-center">
              {tabList.map((val, i) => (
                <Tab key={i}>{val}</Tab>
              ))}
            </TabList>
            {/* End tablist */}

              <TabPanel>
                <div className="portfolio-content row lightbox-gallery">
                  {AllContent.map((val, i) => (
                    <div
                      className="col-sm-6 col-lg-4 grid-item product"
                      key={i}
                      data-aos="fade-right"
                      data-aos-duration="1200"
                      data-aos-delay={val.delayAnimation}
                      onClick={() => toggleModal(val.img)}
                    >
                      <div className="portfolio-box-01">
                        <div className="portfolio-img">
                          <div className="portfolio-info">
                            <h5 style={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow:'ellipsis', fontSize: '1.1rem'}}>
                              {val.title}
                            </h5>
                            <span >
                              {val.subTitle}
                            </span>
                          </div>
                          {/* End .portfolio-info */}
                          <div className="blog-img">
                            <img src={`img/heimages/bookcovers/${val.img}.png`} alt={val.alterText}></img>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {/* End list wrapper */}
              </TabPanel>

              <TabPanel>
                <div className="portfolio-content row lightbox-gallery">
                  {TrialsOfTheHeartContent.map((val, i) => (
                    <div
                      className="col-sm-6 col-lg-4 grid-item product"
                      key={i}
                      data-aos="fade-right"
                      data-aos-duration="1200"
                      data-aos-delay={val.delayAnimation}
                      onClick={() => toggleModal(val.img)}
                    >
                      <div className="portfolio-box-01">
                        <div className="portfolio-img">
                          <div className="portfolio-info">
                            <h5 style={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow:'ellipsis', fontSize: '1.1rem'}}>
                                {val.title}
                            </h5>
                            <span>
                              {val.subTitle}
                            </span>
                          </div>
                          {/* End .portfolio-info */}
                          <div className="blog-img">
                            <img src={`img/heimages/bookcovers/${val.img}.png`} alt={val.alterText}></img>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {/* End list wrapper */}
              </TabPanel>

              <TabPanel>
                <div className="portfolio-content row lightbox-gallery">
                  {StandAloneContent.map((val, i) => (
                    <div
                      className="col-sm-6 col-lg-4 grid-item product"
                      key={i}
                      data-aos="fade-right"
                      data-aos-duration="1200"
                      data-aos-delay={val.delayAnimation}
                      onClick={() => toggleModal(val.img)}
                    >
                      <div className="portfolio-box-01">
                        <div className="portfolio-img">
                          <div className="portfolio-info">
                            <h5>
                                {val.title}
                            </h5>
                            <span>
                              {val.subTitle}
                            </span>
                          </div>
                          {/* End .portfolio-info */}
                          <div className="blog-img">
                            <img src={`img/heimages/bookcovers/${val.img}.png`} alt={val.alterText}></img>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {/* End list wrapper */}
                  </TabPanel>
              {/*<TabPanel>
                <div className="portfolio-content row lightbox-gallery">
                  {GalacticUniveristyContent.map((val, i) => (
                    <div
                      className="col-sm-6 col-lg-4 grid-item product"
                      key={i}
                      data-aos="fade-right"
                      data-aos-duration="1200"
                      data-aos-delay={val.delayAnimation}
                      onClick={() => toggleModal(val.img)}
                    >
                      <div className="portfolio-box-01">
                        <div className="portfolio-img">
                          <div className="portfolio-info">
                            <h5 style={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow:'ellipsis', fontSize: '1.1rem'}}>
                                {val.title}
                            </h5>
                            <span>
                              {val.subTitle}
                            </span>
                          </div>
                          {/* End .portfolio-info 
                          <div className="blog-img">
                            <img src={`img/heimages/bookcovers/${val.img}.png`} alt={val.alterText}></img>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {/* End list wrapper 
              </TabPanel>*/}
            {/* End tabpanel */}
          </Tabs>
        </div>
      </div>
            {/* Start Modal for Blog-1 */}
      <Modal
        isOpen={tohIsOpen}
        onRequestClose={() => toggleModal('toh_cover')}
        contentLabel="My dialog"
        className="custom-modal"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div>
          <button className="close-modal" onClick={() => toggleModal('toh_cover')}>
            <img src="/img/cancel.svg" alt="close icon" />
          </button>
          {/* End close icon */}

          <div className="box_inner">
            <div className="scrollable">
              <div className="blog-grid">
                <div className="blog-img">
                  <img src="img/heimages/bookcovers/ths_cover_big.png" alt="THS book post"></img>
                </div>
                {/* End blog-img */}
                <article className="article">
                  <div className="article-title">
                    <h2>
                      Trials Of The Heart - Debut Novel
                    </h2>
                    <div className="media">
                      <div className="avatar">
                        <img src="img/heimages/logo/acp-logo.png" alt="thumbnail" />
                      </div>
                      <div className="media-body">
                        <br></br>
                        <span>EDITED BY ACTP EDITORIAL SERVICES</span>
                      </div>
                    </div>
                  </div>
                  {/* End .article-title */}

                  <div className="article-content">
                  <p>
                    When the Folk she trusts above all others breaks her heart, Kali is left feeling helpless and alone during the most crucial time in her life. 
                    She pulls herself out of the darkness through sheer determination and spite, becoming the warrior she longed to be. 
                    But before she has time to revel in her victory, the Blessed unveils that Kali is more important than she realizes.</p>
                  <p>
                    With the hint of what her future may hold, Kali fights every instinct that screams to run and hide. 
                    Falling back on the determination and courage she has cultivated throughout the years, she faces the questions her destiny relies on.
                  </p>
                  <p>
                    Will she be able to confront her past to forgive those she once called family? 
                    Even if it may mean sacrificing everything she has fought to become? 
                    Can the walls she built around her heart be broken down to learn to trust again? 
                    Or, more terrifyingly, soften her heart toward a kind of love she promised herself never to feel?
                  </p>
                  <p>
                    H.E. Gober's Trials of the Heart is an emotionally charged fantasy romance series that will test your resolve and bring you hope and joy throughout each character's journey.
                  </p>  
                  </div>
                  {/* End article content */}
                </article>
                {/* End Article */}

                {/* End .contact Form */}
              </div>
            </div>
          </div>
        </div>
        {/* End modal box news */}
      </Modal>
      <Modal
        isOpen={sohIsOpen}
        onRequestClose={() => toggleModal('soh_cover')}
        contentLabel="My dialog"
        className="custom-modal"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div>
          <button className="close-modal" onClick={() => toggleModal('soh_cover')}>
            <img src="/img/cancel.svg" alt="close icon" />
          </button>
          {/* End close icon */}

          <div className="box_inner">
            <div className="scrollable">
              <div className="blog-grid">
                <div className="blog-img">
                  <img src="img/heimages/bookcovers/coming_soon_992_by_661.png" alt="book post"></img>
                </div>
                {/* End blog-img */}
                <article className="article">
                  <div className="article-title">
                    <h2>
                      Trials Of The Heart - Book 2
                    </h2>
                  </div>

                  <div className="article-content">
                    <p>
                      Kali&apos;s journey is only beginning. In Book Two, she plunges into a new realm of discovery and healing, 
                      uncovering the grand truth of her destiny. It will be released in 2024. 
                      The cover and title will be unveiled on TikTok before I share it here. 
                      I am on the edge of my seat as I prepare to unleash the rest of this story upon the world. 
                    </p>
                  </div>
                </article>
                {/* End Article */}
              </div>
            </div>
          </div>
        </div>
        {/* End modal box news */}
      </Modal>
      <Modal
        isOpen={satIsOpen}
        onRequestClose={() => toggleModal('owned_cover')}
        contentLabel="My dialog"
        className="custom-modal"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div>
          <button className="close-modal" onClick={() => toggleModal('owned_cover')}>
            <img src="/img/cancel.svg" alt="close icon" />
          </button>
          {/* End close icon */}

          <div className="box_inner">
            <div className="scrollable">
              <div className="blog-grid">
                <div className="blog-img">
                  <img src="img/heimages/bookcovers/owned_cover_big.png" alt="Owned book post"></img>
                </div>
                {/* End blog-img */}
                <article className="article">
                  <div className="article-title">
                    <h2>
                      Owned - Dark Fantasy Romance Novel
                    </h2>
                    <div className="media">
                      <div className="avatar">
                        <img src="img/heimages/logo/lcp-logo.png" alt="thumbnail" />
                      </div>
                      <div className="media-body">
                        <br></br>
                        <span>EDITED BY LACY CHANTELL PUBLISHING</span>
                      </div>
                    </div>
                  </div>
                  {/* End .article-title */}

                  <div className="article-content">
                  <h3><u>Nyx:</u></h3>
                  <p>
                    She could have chosen to give up and die at the hands of the Keryth who held her captive and tortured her for years after her parents&apos; brutal demise. 
                    Instead, Nyx embraces the darkness that was awakened in her soul and uses the flames of her anger to transform into the weapon she needs to be.</p>
                  <p>
                    With morbid determination, she leaves a trail of blood and gore in her wake as she cuts down each and every Keryth that brings her a step closer to HIM. 
                    Unfortunately, one of her targets is unlike the others and she finds herself owned by her enemy once more.
                  </p>
                  <h3><u>Felix:</u></h3>
                  <p>
                    As the Prince of the Keryth, there are certain expectations of how he must act and traditions that must be followed. 
                    And with a father who is brutal and unforgiving, Felix has no choice but to keep his mask firmly in place. 
                    Because if the King and Queen found out what their son was doing behind their backs, they would kill him on the spot. 
                  </p>
                  <p>
                    It isn&apos;t until he finds himself being followed by the notorious ghost who has the Keryth locking their doors at night,
                    that he finally believes there is hope for his dreams of the future.  
                  </p>
                  <p>
                    Will Nyx be able to go against every instinct she has cultivated over the years and give into the male who has her questioning everything she ever knew? 
                    Can Felix, after falling hard for the most dangerous person in the kingdom, find a way to convince her that they are on the same team? Or will they both fail, 
                    leaving the Cursed Kingdom with no hope at all?
                  </p>
                  <p>
                    H.E. Gober&apos;s Owned is a dark fantasy romance that will have you cheering for the morally gray and hopeless romantics.
                  </p>  
                  </div>
                  {/* End article content */}
                </article>
                {/* End Article */}

                {/* End .contact Form */}
              </div>
            </div>
          </div>
        </div>
        {/* End modal box news */}
      </Modal>
      {/*<Modal
        isOpen={novIsOpen}
        onRequestClose={() => toggleModal('novella_cover')}
        contentLabel="My dialog"
        className="custom-modal"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div>
          <button className="close-modal" onClick={() => toggleModal('novella_cover')}>
            <img src="/img/cancel.svg" alt="close icon" />
          </button>
          {/* End close icon *

          <div className="box_inner">
            <div className="scrollable">
              <div className="blog-grid">
                <div className="blog-img">
                  <img src="img/heimages/bookcovers/coming_soon_992_by_661.png" alt="book post"></img>
                </div>
                {/* End blog-img */}
              {/*  <article className="article">
                  <div className="article-title">
                    <h2>
                      Trials Of The Heart - Book 2
                    </h2>
                    <div className="media">
                      <div className="avatar">
                        <img src="img/testimonial/team-1.jpg" alt="thumbnail" />
                      </div>
                      <div className="media-body">
                        <label>BY ALEX</label>
                        <span>26 FEB 2020</span>
                      </div>
                    </div>
                  </div>

              {/*    <div className="article-content">
                    <p>
                      Aenean eleifend ante maecenas pulvinar montes lorem et
                      pede dis dolor pretium donec dictum. Vici consequat justo
                      enim. Venenatis eget adipiscing luctus lorem. Adipiscing
                      veni amet luctus enim sem libero tellus viverra venenatis
                      aliquam. Commodo natoque quam pulvinar elit.
                    </p>
                  </div>
                </article>*/}
                {/* End Article 
              </div>
            </div>
          </div>
        </div>
        {/* End modal box news 
      </Modal>
      <Modal
        isOpen={satIsOpen}
        onRequestClose={() => toggleModal('sat_cover')}
        contentLabel="My dialog"
        className="custom-modal"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div>
          <button className="close-modal" onClick={() => toggleModal('sat_cover')}>
            <img src="/img/cancel.svg" alt="close icon" />
          </button>
          {/* End close icon 

          <div className="box_inner">
            <div className="scrollable">
              <div className="blog-grid">
                <div className="blog-img">
                  <img src="img/heimages/bookcovers/coming_soon_992_by_661.png" alt="book post"></img>
                </div>
                {/* End blog-img */}
              {/*  <article className="article">
                  <div className="article-title">
                    <h2>
                      Trials Of The Heart - Book 2
                    </h2>
                    <div className="media">
                      <div className="avatar">
                        <img src="img/testimonial/team-1.jpg" alt="thumbnail" />
                      </div>
                      <div className="media-body">
                        <label>BY ALEX</label>
                        <span>26 FEB 2020</span>
                      </div>
                    </div>
                  </div>

              {/*    <div className="article-content">
                    <p>
                      Aenean eleifend ante maecenas pulvinar montes lorem et
                      pede dis dolor pretium donec dictum. Vici consequat justo
                      enim. Venenatis eget adipiscing luctus lorem. Adipiscing
                      veni amet luctus enim sem libero tellus viverra venenatis
                      aliquam. Commodo natoque quam pulvinar elit.
                    </p>
                  </div>
                </article>
                {/* End Article 
              </div>
            </div>
          </div>
        </div>
        {/* End modal box news 
      </Modal>*/}
      {/*<Modal
        isOpen={rdmbbfIsOpen}
        onRequestClose={() => toggleModal('rdmbbf_cover')}
        contentLabel="My dialog"
        className="custom-modal"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div>
          <button className="close-modal" onClick={() => toggleModal('rdmbbf_cover')}>
            <img src="/img/cancel.svg" alt="close icon" />
          </button>
          {/* End close icon 

          <div className="box_inner">
            <div className="scrollable">
              <div className="blog-grid">
                <div className="blog-img">
                  <img src="img/heimages/bookcovers/gus_cover_big.png" alt="gu book post"></img>
                </div>
                {/* End blog-img 
                <article className="article">
                  <div className="article-title">
                    <h2>
                      Revenge Dating My Brother&apos;s Best Friend
                    </h2>
                    <div className="media">
                      <div className="avatar">
                        <img src="img/heimages/logo/acp-logo.png" alt="thumbnail" />
                      </div>
                      <div className="media-body">
                        <br></br>
                        <span>EDITED BY ACTP EDITORIAL SERVICES</span>
                      </div>
                    </div>
                  </div>
                  {/* End .article-title 

                  <div className="article-content">
                  <p>
                    Coming soon!
                  </p>
                  </div>
                  {/* End article content 
                </article>
                {/* End Article */}

                {/* End .contact Form 
              </div>
            </div>
          </div>
        </div>
        {/* End modal box news 
      </Modal>*/}
    </SimpleReactLightbox>
    
  );
};

export default Books;
