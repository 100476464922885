import React, { useState } from "react";
import Modal from "react-modal";
import Contact from "../contact/Contact";

Modal.setAppElement("#root");

const News = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  //const [isOpen3, setIsOpen3] = useState(false);

  function toggleModalOne() {
    setIsOpen(!isOpen);
  }
  function toggleModalTwo() {
    setIsOpen2(!isOpen2);
  }
  /*function toggleModalThree() {
    setIsOpen3(!isOpen3);
  }*/

  return (
    <>
      <div className="row">
        <div
          className="col-lg-4 col-md-6 my-3"
          data-aos="fade-right"
          data-aos-duration="1200"
        >
          <div className="blog-post" onClick={toggleModalOne}>
            <div className="blog-img">
              <div className="data">
                <span>3</span>
                <small>Jan</small>
              </div>

              <img src="img/heimages/news/Welcome.png" alt="welcome post"></img>
            </div>
            {/* End blog-img */}

            <div className="blog-info">
              <p>
              This is so exciting. I have started book after book but never fully completed one. 
              However, with the help of two wonderful ladies, I finally got the confidence to write one from start to finish. 
              It has been surreal, and I can&apos;t believe I have made it here. 
              </p>
              <div className="btn-bar">Read More</div>
            </div>
            {/* End blog-info */}
          </div>
          {/* End blog-post */}
        </div>
        {/* End .col for blog-1 */}

        <div
          className="col-lg-4 col-md-6 my-3"
          data-aos="fade-right"
          data-aos-duration="1200"
          data-aos-delay="100"
        >
          <div className="blog-post" onClick={toggleModalTwo}>
            <div className="blog-img">
              <div className="data">
                <span>29</span>
                <small>June</small>
              </div>

              <img src="img/heimages/news/release_date_announcement.png" alt="release date post"></img>
            </div>
            {/* End blog-img */}

            <div className="blog-info">
              <p>
                I am so excited to finally announce that I have a release date for my debut novel, Trials of the Heart. 
                It has been an incredible journey to get here. It will be going out to Beta Readers within the next few weeks. 
                Then I will have to make the final edits. And lastly, it will be going out to ARC Readers right before release. 
                If you are interested in becoming an ARC Reader, I will be announcing when I need them on TikTok and Instagram.
              </p>
              <div className="btn-bar">Read More</div>{" "}
            </div>
            {/* End blog-info */}
          </div>
          {/* End blog-post */}
        </div>
        {/* End .col for blog-1 

        <div
          className="col-lg-4 col-md-6 my-3"
          data-aos="fade-right"
          data-aos-duration="1200"
          data-aos-delay="200"
        >
          <div className="blog-post" onClick={toggleModalThree}>
            <div className="blog-img">
              <div className="data">
                <span>22</span>
                <small>Apr</small>
              </div>

              <img src="img/blog/blog-1.jpg" alt="blog post"></img>
            </div>
            {/* End blog-img 

            <div className="blog-info">
              <h6>Lorem ipsum 3</h6>
              <p>
                Estibulum eu ante sed dui porta porttitor. Nullam a placerat
                velit. Suspendisse non ligula felis
              </p>
              <div className="btn-bar">Read More</div>
            </div>
            {/* End blog-info 
          </div>
          {/* End blog-post 
        </div>
        {/* End .col for blog-1 */}
      </div>
      {/* End .row */}

      {/* Start Modal for Blog-1 */}
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModalOne}
        contentLabel="My dialog"
        className="custom-modal"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div>
          <button className="close-modal" onClick={toggleModalOne}>
            <img src="/img/cancel.svg" alt="close icon" />
          </button>
          {/* End close icon */}

          <div className="box_inner">
            <div className="scrollable">
              <div className="blog-grid">
                <div className="blog-img">
                  <img src="img/heimages/news/Welcome.png" alt="blog post"></img>
                </div>
                {/* End blog-img */}
                <article className="article">
                  <div className="article-title">
                    <div className="media">
                      <div className="avatar">
                        <img src="img/testimonial/team-1.jpg" alt="thumbnail" />
                      </div>
                      <div className="media-body">
                        <label>BY H.E. GOBER</label>
                        <span>03 JAN 2023</span>
                      </div>
                    </div>
                  </div>
                  {/* End .article-title */}

                  <div className="article-content">
                    <p>
                    This is so exciting. I have started book after book but never fully completed one. 
                    However, with the help of two wonderful ladies, I finally got the confidence to write one from start to finish. 
                    It has been surreal, and I can&apos;t believe I have made it here. 
                    </p>
                    <p>
                    There has been a lot of grumbling and frustration along the way. 
                    Every part of this has been a crazy learning experience. 
                    From fighting with imposter syndrome to editing and formatting, I was tempted to pull my hair out multiple times. 
                    Even with all the stress, it has also been very rewarding.
                    </p>
                    <p>
                    I have learned so many new skills and found that I am capable of more than I have ever thought possible. 
                    So, if you want to write, write. Get your story out on paper even if you don&apos;t plan on publishing it. 
                    Your story deserves to be told, and you may end up surprising yourself. 
                    </p>
                    <p>
                    I hope you will join me on this extraordinary adventure of creating new worlds and characters. 
                    And hopefully, they will grab your heart like they have mine. 
                    </p>
                    <h4>With So Much Love,</h4>
                    <h1 className="font-alt">H.E. Gober</h1>

                  </div>
                  {/* End article content */}
                </article>
                {/* End Article */}

                <div className="contact-form article-comment">
                  <h4>Leave a Reply</h4>
                  <Contact />
                </div>
                {/* End .contact Form */}
              </div>
            </div>
          </div>
        </div>
        {/* End modal box news */}
      </Modal>
      {/* End  Modal for Blog-1 */}

      {/* Start Modal for Blog-2 */}
      <Modal
        isOpen={isOpen2}
        onRequestClose={toggleModalTwo}
        contentLabel="My dialog"
        className="custom-modal"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div>
          <button className="close-modal" onClick={toggleModalTwo}>
            <img src="/img/cancel.svg" alt="close icon" />
          </button>
          {/* End close icon */}

          <div className="box_inner">
            <div className="scrollable">
              <div className="blog-grid">
                <div className="blog-img">
                  <img src="img/heimages/news/release_date_announcement.png" alt="blog post"></img>
                </div>
                {/* End blog-img */}
                <article className="article">
                  <div className="article-title">
                    <div className="media">
                      <div className="avatar">
                        <img src="img/heimages/logo/heg-logo.png" alt="thumbnail" />
                      </div>
                      <div className="media-body">
                        <label>BY H.E. GOBER</label>
                        <span>29 JUN 2023</span>
                      </div>
                    </div>
                  </div>
                  {/* End .article-title */}

                  <div className="article-content">
                    <p>
                      I am so excited to finally announce that I have a release date for my debut novel, Trials of the Heart. 
                      It has been an incredible journey to get here. It will be going out to Beta Readers within the next few weeks. 
                      Then I will have to make the final edits. And lastly, it will be going out to ARC Readers right before release.
                      If you are interested in becoming an ARC Reader, I will be announcing when I need them on TikTok and Instagram.
                    </p>
                    <p>
                      I can&apos;t wait to finally share the story that has been causing sleepless nights and turning me into an even bigger introvert
                      than I already am. And if you are as excited as I am, keep an eye out on September 2, 2023, when it will finally be out in the 
                      world for everyone to read.
                    </p>
                    <p>
                      Thank you to everyone who has shown me support through the challenging process! I couldn&apos;t have done it without you! 
                    </p>
                    <h4>With So Much Love,</h4>
                    <h1 className="font-alt">H.E. Gober</h1>
                  </div>
                  {/* End article content */}
                </article>
                {/* End Article */}

                <div className="contact-form article-comment">
                  <h4>Leave a Reply</h4>
                  <Contact />
                </div>
                {/* End .contact Form */}
              </div>
            </div>
          </div>
        </div>
        {/* End modal box news */}
      </Modal>
      {/* End  Modal for Blog-2 

      {/* Start Modal for Blog-3 
      <Modal
        isOpen={isOpen3}
        onRequestClose={toggleModalThree}
        contentLabel="My dialog"
        className="custom-modal"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div>
          <button className="close-modal" onClick={toggleModalThree}>
            <img src="/img/cancel.svg" alt="close icon" />
          </button>
          {/* End close icon 

          <div className="box_inner">
            <div className="scrollable">
              <div className="blog-grid">
                <div className="blog-img">
                  <img src="img/blog/blog-3.jpg" alt="blog post"></img>
                </div>
                {/* End blog-img 
                <article className="article">
                  <div className="article-title">
                    <h2>
                      I Will Tell You The Truth About Design In The Next 60
                      Seconds.
                    </h2>
                    <div className="media">
                      <div className="avatar">
                        <img src="img/testimonial/team-3.jpg" alt="thumbnail" />
                      </div>
                      <div className="media-body">
                        <label>BY RIO</label>
                        <span>12 AUG 2020</span>
                      </div>
                    </div>
                  </div>
                  {/* End .article-title 

                  <div className="article-content">
                    <p>
                      Aenean eleifend ante maecenas pulvinar montes lorem et
                      pede dis dolor pretium donec dictum. Vici consequat justo
                      enim. Venenatis eget adipiscing luctus lorem. Adipiscing
                      veni amet luctus enim sem libero tellus viverra venenatis
                      aliquam. Commodo natoque quam pulvinar elit.
                    </p>
                    <p>
                      Eget aenean tellus venenatis. Donec odio tempus. Felis
                      arcu pretium metus nullam quam aenean sociis quis sem
                      neque vici libero. Venenatis nullam fringilla pretium
                      magnis aliquam nunc vulputate integer augue ultricies
                      cras. Eget viverra feugiat cras ut. Sit natoque montes
                      tempus ligula eget vitae pede rhoncus maecenas
                      consectetuer commodo condimentum aenean.
                    </p>
                    <h4>What are my payment options?</h4>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    </p>
                    <blockquote>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua. Ut enim ad minim veniam.
                      </p>
                      <p className="blockquote-footer">
                        Someone famous in{" "}
                        <cite title="Source Title">Dick Grayson</cite>
                      </p>
                    </blockquote>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </p>
                  </div>
                  {/* End article content 
                </article>
                {/* End Article 

                <div className="contact-form article-comment">
                  <h4>Leave a Reply</h4>
                  <Contact />
                </div>
                {/* End .contact Form 
              </div>
            </div>
          </div>
        </div>
        {/* End modal box news 
      </Modal>
       {End  Modal for Blog-3 */}
    </>
  );
};

export default News;
