import React from "react";
import {
  FaInstagram,
  FaTiktok
} from "react-icons/fa";

const SocialShare = [
  //{ Social: <FaFacebookF />, link: "https://www.facebook.com/author.h.e.gober/" },
  { Social: <FaInstagram />, link: "https://www.instagram.com/author_h.e.gober/" },
  { Social: <FaTiktok/>, link: "https://www.tiktok.com/@h.e.gober_author"}
];

const Social = () => {
  return (
    <div className="nav social-icons justify-content-center text-sm-center justify-content-md-end">
      {SocialShare.map((val, i) => (
        <a key={i} href={`${val.link}`} rel="noreferrer" target="_blank">
          {val.Social}
        </a>
      ))}
    </div>
  );
};

export default Social;
