import React, {useEffect, useRef} from "react";
import {withRouter} from "react-router-dom";

const Merchandise = () => {
  const storeDiv = useRef(null);
  const scriptRef = useRef(null);

  window.localStorage.setItem("show_ecwid_logs","true")
  window.ecwid_script_defer = true;
  window.ecwid_dynamic_widgets = true;
  window.ec = window.ec || Object();
  window.ec.storefront = window.ec.storefront || Object();
  window.ec.enable_catalog_on_one_page = true;
  window._xnext_initialization_scripts = [{
    widgetType: 'ProductBrowser',
    id: 'my-store-91290554',
    arg: ["id=productBrowser", "views=grid(20,3)"]  }
  ];

  var script = document.createElement('script');
  script.charset = 'utf-8';
  script.type = 'text/javascript';
  script.src = 'https://app.ecwid.com/script.js?####';
  script.defer = true;
  script.ref=scriptRef;


  useEffect(() => {
    if(!scriptRef.current){
      storeDiv.current.appendChild(script);
    }
  });

  return (
      <div className="bob">
        <div id="my-store-91290554" ref={storeDiv}></div>
        <div className="ec-cart-widget" ></div>
      </div>
  );
};

export default withRouter(Merchandise);
